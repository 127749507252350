import { graphql } from 'gatsby'
import { GatsbyFluidImageProps } from 'gatsby-source-sanity'
import React from 'react'
import styled from 'styled-components'
import { layout } from '../components/GlobalStyles/vars/layout'
import { HeroImage } from '../components/HeroImage'
import { Layout } from '../components/Layout/Layout'
import { Listing } from '../components/Listing'
import { SEO } from '../helpers/SEO'

const PageContainer = styled.div``

const ServicesContainer = styled.ul`
  ${layout.pageWidth}
  margin-top: 50px;
`

interface Data {
  data: {
    file: {
      childImageSharp: {
        fluid: GatsbyFluidImageProps
      }
    }
    allSanityService: {
      nodes: Array<Service>
    }
  }
}

interface Service {
  id: string
  mainImage: {
    asset: {
      fluid: {
        src: string
      }
    }
  }
  title: string
  seoData: {
    _rawDescription: React.ReactNode
  }
  slug: {
    current: string
  }
}

const Services = ({ data }: Data): JSX.Element => {
  return (
    <>
      <SEO
        title="Services"
        description="Queen City Flying Service has courses for you. Regardless if you're a returning pilot looking to renew his skills, or a new pilot taking flight for the first time."
      />
      <Layout>
        <HeroImage fluid={data.file.childImageSharp.fluid} alt="An aircraft parked on the airport ramp." />
        <PageContainer>
          <h1>Courses and Services</h1>
          <p>
            Queen City Flying Service offers FAA pilot training using the Cessna 172 Skyhawk, the most popular training
            airplane in the industry. The fleet will be expanding soon! Our instructors are also available for training
            and pilot services in client-provided airplanes.
          </p>
          <p>
            Our team of expert flight instructors can work with you to make the perfect path to certification. If you
            are not sure what program is right for you, contact us today for a consultation.
          </p>
          <ServicesContainer>
            {data.allSanityService.nodes.map((service) => {
              return (
                <Listing
                  title={service.title}
                  img={service.mainImage.asset.fluid.src}
                  key={service.id}
                  desc={service.seoData._rawDescription}
                  link={`/services/${service.slug.current}`}
                />
              )
            })}
          </ServicesContainer>
        </PageContainer>
      </Layout>
    </>
  )
}

export default Services

export const query = graphql`
  {
    file(relativePath: { eq: "services.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allSanityService {
      nodes {
        id
        mainImage {
          asset {
            fluid(maxWidth: 100, maxHeight: 100) {
              src
            }
          }
        }
        title
        seoData {
          _rawDescription
        }
        slug {
          current
        }
      }
    }
  }
`
